



























import { Component, Vue } from "vue-property-decorator";

@Component
export default class Things extends Vue {
  things = [
    {
      name: "Depres Kompendium des Kommandeurs",
      description:
        "Ein umfassender Guide für neue Spieler und Commander von Depre.5786",
      href: "https://depre.gitlab.io/kompendium/"
    },
    {
      name: "Next week in WvW",
      description:
        "Zeigt das aktuelle und (basierend auf aktuellen Werten) nächste Matchup",
      href: "https://next.werdes.net"
    },
    {
      name: "gw2efficiency",
      description:
        "Crafting-Rechner, Account-Statistiken, Account-Wert, Durchsuchen aller Charaktere und Banken. Und das kostenlos.",
      href: "https://gw2efficiency.com/"
    },
    {
      name: "gw2mists",
      description:
        "Guides, Giveaways, Leaderboards, Gildenlist, WvW-Builds, uvm.",
      href: "https://gw2mists.com/"
    },
    {
      name: "kills.werdes.net",
      description: "Kill-Tracker für Matchups",
      href: "https://kills.werdes.net"
    },
    {
      name: "Population Tracker",
      description:
        "Zeigt aktuelle und historische Populationswerte aller Server",
      href: "https://pop.apfelcreme.net/"
    }
  ];
}
